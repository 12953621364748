import axios from "axios";
import { AisleSubscriptionCheck } from "pages/api/aisle/check-subscription";
import { OffersGetResponse } from "pages/api/v1/offers";
import { OffersByRetailIdSchemaInterface } from "pages/api/v1/offers/by-retailer-id";
import {
  OfferDetailsItem,
  ZipcodeQuerySchemaInterface,
} from "pages/api/v1/offers/by-zipcode";
import {
  ClaimStatusResponse,
  FetchOfferStatusQuerySchemaInterface,
} from "pages/api/v1/offers/claim-status";
import { FetchOfferDetailsQuerySchemaInterface, OfferDetailResponse } from "pages/api/v1/offers/[id]";
import { OffersQuerySchemaInterface } from "validations/offerspage.validation";
import { SubscribeAisleBodySchemaInterface } from "validations/subscribeToAisle.validation";

export const fetchOffers = async (payload: OffersQuerySchemaInterface) => {
  const { data } = await axios.get<OffersGetResponse>(`/api/v1/offers`, {
    params: payload,
  });
  return data;
};

export type subscribeOfferPayload = {
  brandApiKey: string;
  customerPhoneNumber: string;
  campaignPhoneNumber: string;
  influencerTag?: string;
  utmCampaign?: string;
  utmSource?: string;
  utmMedium?: string;
  utmContent?: string;
  utmTerm?: string;
  referralCode?: string;
  referrerUrl?: string;
  longitude?: number;
  latitude?: number;
  zipCode?: string;
};

export const subscribeOffer = async ({
  brandApiKey,
  ...body
}: subscribeOfferPayload) => {
  const { data } = await axios.post<{ code: string; message: string }>(
    `${process.env.NEXT_PUBLIC_CONSOLE_DASHBOARD_HOST}/api/webhooks/manual-input`,
    {
      campaignPhoneNumber: body.campaignPhoneNumber,
      customerPhoneNumber: body.customerPhoneNumber,
      influencerTag: body.influencerTag,
      latitude: undefined,
      longitude: undefined,
      referralCode: body.referralCode,
      utmCampaign: body.utmCampaign,
      utmMedium: body.utmMedium,
      utmSource: body.utmSource,
      utmContent: body.utmContent,
      utmTerm: body.utmTerm,
      zipCode: body.zipCode,
    },
    {
      headers: {
        "X-API-KEY": brandApiKey,
      },
    }
  );
  return data;
};

export const verifyIfOptedIntoAisle = async ({
  ...body
}: SubscribeAisleBodySchemaInterface) => {
  const { data } = await axios.post<AisleSubscriptionCheck>(
    `/api/aisle/check-subscription`,
    {
      ...body,
    }
  );
  return data;
};

export const fetchAllOffersByZip = async ({
  ...body
}: ZipcodeQuerySchemaInterface) => {
  const { data } = await axios.get<OfferDetailsItem[]>(
    `/api/v1/offers/by-zipcode`,
    { params: body }
  );
  return data;
};

export const fetchOffersByRetailer = async ({
  ...body
}: OffersByRetailIdSchemaInterface) => {
  const { data } = await axios.get<OfferDetailsItem[]>(
    `/api/v1/offers/by-retailer-id`,
    { params: body }
  );
  return data;
};

export const fetchOfferById = async ({
  ...body
}: FetchOfferDetailsQuerySchemaInterface) => {
  const { data } = await axios.get<OfferDetailResponse>(
    `/api/v1/offers/${body.id}`,
    { params: body }
  );
  return data;
};

export const fetchClaimStatus = async ({
  ...body
}: FetchOfferStatusQuerySchemaInterface) => {
  const { data } = await axios.get<ClaimStatusResponse>(
    `/api/v1/offers/claim-status`,
    { params: body }
  );
  return data;
};
