import * as gtag from "@lib/gtag";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchAllOffersByZip,
  fetchClaimStatus,
  fetchOfferById,
  fetchOffers,
  fetchOffersByRetailer,
  subscribeOffer,
  verifyIfOptedIntoAisle,
} from "services/fetch/offers";

import { subscriptionStatusCodes } from "@textnology-inc/shared-types-and-utils";
import { toast } from "hooks/useToast";
import { OffersQuerySchemaInterface } from "validations/offerspage.validation";

export enum OffersQueryEnums {
  OFFERS = "OFFERS",
  FETCH_ALL_OFFERS_BY_ZIP = "FETCH_ALL_OFFERS_BY_ZIP",
  FETCH_ALL_OFFERS_BY_RETAILER_ID = "FETCH_ALL_OFFERS_BY_RETAILER_ID",
  FETCH_OFFER_BY_ID = "FETCH_OFFER_BY_ID",
  FETCH_CLAIM_STATUS = "FETCH_CLAIM_STATUS",
}

export const useOffersQuery = (payload: OffersQuerySchemaInterface) => {
  return useQuery({
    queryKey: [OffersQueryEnums.OFFERS, payload],
    // @ts-expect-error type issue
    queryFn: ({ queryKey }) => fetchOffers(queryKey[1]),
    refetchOnWindowFocus: false,
    retry: 0,
  });
};

export const useSubscribeOfferMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: subscribeOffer,
    onSuccess: (results) => {
      if (results.code === "NewConvoCreated") {
        toast({
          variant: "default",
          description: "You're in! Check your texts for more info.",
        });
        gtag.event({
          action: "sign_up",
          category: `Offers Page`,
          label: `User joined campaign OR audiences`,
        });
      }
      if (results.code === subscriptionStatusCodes.ConvoStopped) {
        toast({
          variant: "destructive",
          description: "You have opted out of this campaign",
        });
      } else if (results.code === subscriptionStatusCodes.UserNotEligible) {
        toast({
          variant: "destructive",
          description:
            "We're sorry, it looks like you've already redeemed with this number for another one of our campaigns! If this is a mistake, please email us at support@gotoaisle.com.",
        });
      } else if (results.code === subscriptionStatusCodes.VoipCustomer) {
        toast({
          variant: "destructive",
          description: "VOIP phone numbers are not permitted.",
        });
      } else if (results.code === subscriptionStatusCodes.PayoutBlocked) {
        toast({
          variant: "destructive",
          description:
            "We are unable to sign you up at this time. Please email us at support@gotoaisle.com.",
        });
      }
      queryClient.invalidateQueries({ queryKey: [OffersQueryEnums.OFFERS] });
      queryClient.invalidateQueries({
        queryKey: [OffersQueryEnums.FETCH_CLAIM_STATUS],
      });
    },
  });
};

export const useVerifyIfOptedIntoAisleMutation = () =>
  useMutation({
    mutationFn: verifyIfOptedIntoAisle,
  });

export const useFetchOffersByZipQuery = ({
  customerId,
  zipcode,
  range,
}: {
  customerId?: string;
  zipcode: string;
  range: string;
}) => {
  return useQuery({
    staleTime: 1000 * 60 * 60 * 24,
    queryKey: [
      OffersQueryEnums.FETCH_ALL_OFFERS_BY_ZIP,
      customerId,
      zipcode,
      range,
    ],
    queryFn: ({ queryKey }) =>
      fetchAllOffersByZip({
        customerId: queryKey[1],
        // cast to string to avoid type error b.c. customerId can be undefined if user's are not signed in
        zipcode: queryKey[2] as string,
        range: queryKey[3],
      }),
    enabled: !!zipcode && !!range,
  });
};

export const useFetchOffersByRetailerQuery = ({
  retailLocationId,
  customerId,
}: {
  retailLocationId: string;
  customerId?: string;
}) => {
  return useQuery({
    staleTime: 1000 * 60 * 60 * 24,
    queryKey: [OffersQueryEnums.OFFERS, retailLocationId, customerId],
    queryFn: ({ queryKey }) =>
      fetchOffersByRetailer({
        // cast to string to avoid type error b.c. customerId can be undefined if user's are not signed in
        retailLocationId: queryKey[1] as string,
        customerId: queryKey[2],
      }),
    enabled: !!retailLocationId,
  });
};

export const useFetchOfferById = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: [OffersQueryEnums.OFFERS, id],
    queryFn: ({ queryKey }) => fetchOfferById({ id: queryKey[1] }),
    enabled: !!id,
  });
};

export const useFetchClaimStatus = ({
  campaignPhoneNumber,
  customerPhoneNumber,
}: {
  campaignPhoneNumber: string;
  customerPhoneNumber: string;
}) => {
  return useQuery({
    queryKey: [
      OffersQueryEnums.FETCH_CLAIM_STATUS,
      campaignPhoneNumber,
      customerPhoneNumber,
    ],
    queryFn: ({ queryKey }) =>
      fetchClaimStatus({
        campaignPhoneNumber: queryKey[1],
        customerPhoneNumber: queryKey[2],
      }),
    enabled: !!campaignPhoneNumber && !!customerPhoneNumber,
  });
};
